import { reactive } from 'vue'

// Create a new store instance.
const state = reactive({
  token: ''
})
const methods = {

}
export default {
  state,
  methods
}

import axios from 'axios'
import cookies from 'vue-cookies'

const httpClient = axios.create({
  timeout: 2000 * 12, // Cree una instancia de axios, establezca el tiempo de espera en 24s
  withCredentials: false
})
const url = 'https://api.euphoriahealthcare.co.uk'
// function login
export function login (username, email, password) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
  let json = ''
  if (username !== '') {
    json = {
      username: username,
      password: password
    }
  } else if (email !== '') {
    json = {
      email: email,
      password: password
    }
  }
  return httpClient.post(url + '/auth/auth', json, { headers: headers })
}
// function return profile
export function getProfile () {
  const headers = {
    Authorization: 'Bearer ' + cookies.get('_q3@fd2'),
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
  return httpClient.get(url + '/profile/profile', { headers: headers })
}
// function return files
export function getFiles () {
  const headers = {
    Authorization: 'Bearer ' + cookies.get('_q3@fd2'),
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
  return httpClient.get(url + '/documents/documents', { headers: headers })
}
// function return user
export function getUser () {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + '/users/users', { headers: headers })
}
// function return clients
export function getClients () {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + '/clients/clients', { headers: headers })
}
// function return client by id
export function getClientById (id) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + `/clients/clients?id=${encodeURIComponent(id)}`, { headers: headers })
}
// function send reset email
export function sendResetEmail (email) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + `/reset/reset?email=${encodeURIComponent(email)}`, { headers: headers })
}

// function change password
export function changePassword (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
  return httpClient.put(url + '/reset/reset', json, { headers: headers })
}

// function return countries
export function getCountries () {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + '/countries/countries', { headers: headers })
}
// function register
export function register (json) {
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
  return httpClient.post(url + '/registration/registration', json, { headers: headers })
}

// function update profile
export function updateProfile (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.put(url + '/profile/profile', json, { headers: headers })
}

// function insert profile
export function insertProfile (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.post(url + '/profile/profile', json, { headers: headers })
}
// function insert documents
export function insertDocuments (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.post(url + '/documents/documents', json, { headers: headers })
}
// function update Users
export function updateUser (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.put(url + '/users/users', json, { headers: headers })
}
// function activete Users
export function activateUser (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
  return httpClient.put(url + '/activation/activation', json, { headers: headers })
}
// function delete client
export function deleteClient (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return fetch(url + '/clients/clients', {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(json)
  })
}
// function insert client
export function insertClient (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.post(url + '/clients/clients', json, { headers: headers })
}
export function sendForm (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
  return httpClient.post(url + '/contact/contact', json, { headers: headers })
}
// function update client
export function updateClient (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.put(url + '/clients/clients', json, { headers: headers })
}
// function return shifts
export function getShifts () {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + '/shifts/shifts', { headers: headers })
}
// function return user shifts
export function getShiftsByUser () {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + '/shifts/shifts?idUser=', { headers: headers })
}
// function insert client
export function insertShift (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.post(url + '/shifts/shifts', json, { headers: headers })
}

// function update shift
export function updateShift (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.put(url + '/shifts/shifts', json, { headers: headers })
}

// function delete shifts
export function deleteShift (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return fetch(url + '/shifts/shifts', {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(json)
  })
}

// select shift by id
export function updateShiftSelected (taskId) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.put(url + '/profile/profile', taskId, { headers: headers })
}
// delete user shift
export function deleteUserShift (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return fetch(url + '/profile/profile', {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(json)
  })
}
// delete file
export function deleteDocument (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return fetch(url + '/documents/documents', {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(json)
  })
}
// function return notification by user
export function getNotificationByUser () {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.get(url + '/notification/notification?idUser=this', { headers: headers })
}
// update notification active/not active
export function updateNotificationActive (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.put(url + '/notification/notification', json, { headers: headers })
}
// delete all notification
export function deleteAllNotifications () {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.delete(url + '/notification/notification', { headers: headers })
}
export function deleteAllSeenNotification (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return fetch(url + '/notification/notification', {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(json)
  })
}
// function insert notification
export function insertNotification (json) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer ' + cookies.get('_q3@fd2')
  }
  return httpClient.post(url + '/notification/notification', json, { headers: headers })
}

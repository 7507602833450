<template>
  <div id="page-top">
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
      <i class="fas fa-angle-up"></i>
    </a>
  </div>
</template>

<script>

export default {
  name: 'go-top',
  mounted () {
    // Scroll to top button appear
    document.addEventListener('scroll', this.findScroll)

    // Smooth scrolling
    document.querySelector('.scroll-to-top').addEventListener('click', function (e) {
      document.querySelector('html, body').animate({
        scrollTop: window.scrollTo(0, 0)
      }, 1000, 'easeInOutExpo')
      e.preventDefault()
    })
  },
  methods: {
    // make visible button scroll
    findScroll () {
      const scrollDistance = window.scrollY
      if (scrollDistance > 200) {
        document.querySelector('.scroll-to-top').style.display = 'block'
      } else {
        document.querySelector('.scroll-to-top').style.display = 'none'
      }
    }
  },
  unmounted () {
    document.removeEventListener('scroll', this.findScroll)
  }
}
</script>

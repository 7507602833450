import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from './router'
// import store from './store/store'
import './assets/style/main.scss'
import './assets/style/util.scss'
import './assets/style/passwordChecker.scss'
import 'wowjs/css/libs/animate.css'
import 'animate.css/animate.min.css'
import Toast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
import moment from 'moment'

const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false
  }
  // You can modify the toast if you want
  return toast
}

const app = createApp(App).use(router)
app.config.globalProperties.$moment = moment
app.use(Toast, filterBeforeCreate)
installElementPlus(app)
// app.use(store)
app.mount('#app')

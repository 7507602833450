import cookies from 'vue-cookies'
import { useToast } from 'vue-toastification'
// notification
export function notification (text, type = 'error', position = 'down', time = 5000) {
  const toast = useToast()
  if (type === 'error' || type === '') {
    toast.error(text, {
      position: position === 'down' ? 'bottom-right' : 'top-right',
      timeout: time === 5000 ? 5000 : time,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      maxToasts: 1,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false
    })
  } else if (type === 'warning') {
    toast.warning(text, {
      position: position === 'down' ? 'bottom-right' : 'top-right',
      timeout: time === 5000 ? 5000 : time,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      maxToasts: 1,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false
    })
  } else {
    toast.success(text, {
      position: position === 'down' ? 'bottom-right' : 'top-right',
      timeout: time === 5000 ? 5000 : time,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      maxToasts: 1,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false
    })
  }
}
// check element in array
export function inArray (haystack, needle) {
  const length = haystack.length
  for (let i = 0; i < length; i++) {
    if (haystack[i] === needle) return true
  }
  return false
}
export function replace_(text) {
  return text.replace(/_/g, ' ')
}
export function formatBytes(bytes, decimals = 2, k = 1024) {
  if (bytes === 0) return '0 Bytes'

  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
// validate email
/* eslint-disable no-unused-vars */
export function validateEmail (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
// remove token
export function removeToken () {
  cookies.remove('_q3@fd2')
  sessionStorage.removeItem('IDUSER')
}
// verify token
export function verifyToken () {
  if (cookies.get('_q3@fd2') !== null) return true
  return false
}
// generate random color
export function getRandomColor () {
  const maxVal = 0xFFFFFF // 16777215
  let randomNumber = Math.random() * maxVal
  randomNumber = Math.floor(randomNumber)
  randomNumber = randomNumber.toString(16)
  const randColor = randomNumber.padStart(6, 0)
  return `#${randColor.toUpperCase()}`
}
export function getRandomColorLight() {
  const letters = 'BCDEF'.split('')
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)]
  }
  return color
}
export function waitForElm(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}
export function getRandomColorDark(colorR) {
  const letters = '0123456789A'.split('')
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)]
    if (colorR === color) {
      color = '#'
      color += letters[Math.floor(Math.random() * letters.length)]
    }
  }
  return color
}
// check password
export function checkPassword (password, confirmPassword) {
  let passwordLength = ''
  let validPasswordLength = false
  let typed = false
  let containsSymbol = false
  let containsNumber = false
  let containsUpperCase = false
  passwordLength = password.length

  if (passwordLength > 7) validPasswordLength = true
  else validPasswordLength = false

  if (validPasswordLength > 0) typed = true
  else typed = false

  containsSymbol = /[!@#$%^&*(),.?":{}|<>]/g.test(password)
  containsNumber = /\d/.test(password)
  containsUpperCase = /[A-Z]/.test(password)
  // Check if the password is valid
  if (containsSymbol && containsNumber) {
    if (containsUpperCase && validPasswordLength && (password === confirmPassword)) return true
  } else return false
}

export function sortByDateTime(a, b) {
  return Date.parse(b.startDateTimeNotif) - Date.parse(a.startDateTimeNotif)
}
// function calculateTimeAgo

export function timeSince(date) {
  if (typeof date !== 'object') date = new Date(date)

  const seconds = Math.floor((new Date() - date) / 1000)
  let intervalType

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    intervalType = 'year'
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = 'month'
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = 'day'
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = 'hour'
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = 'minute'
          } else {
            interval = seconds
            intervalType = 'second'
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's'
  }

  return interval + ' ' + intervalType
}
export function toTimestamp(strDate) {
  const datum = Date.parse(strDate)
  return datum / 1000
}

export function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}
// function to extrect the initials
export function getInitials (name) {
  // eslint-disable-next-line prefer-regex-literals
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
  let initials = [...name.matchAll(rgx)] || []
  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()
  return initials
}
// calendar functions
let eventGuid = 0
const todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00'
  }
]

export function onlyNumbers(e) {
  if (e.charCode === 0 || /\d/.test(String.fromCharCode(e.charCode))) return true
  else e.preventDefault()
}

export function createEventId () {
  return String(eventGuid++)
}
// end calendar functions
// increse date
export function incrementDate (dateInput, increment) {
  const dateFormatTotime = new Date(dateInput)
  const increasedDate = new Date(dateFormatTotime.getTime() + (increment * 86400000))
  return increasedDate
}

<template>
  <div class="sticky-top disable-select" ref="toolbar">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand d-inline-block" @click="handleSelect('1')"
        ><img v-if="!drawer || loginSuccess" class="img-fluid disable-select" src="../../assets/images/logo.png"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="navbar-collapse"
        @click="drawer = !drawer"
      >
        <span class="navbar-toggler-icon disable-select"></span>
      </button>
      <el-drawer
        :model-value="drawer"
        :with-header="false"
        :before-close="handleClose">
      <div
        class="custom-nav-mobile dis-flex w-full disable-select"
      >
      <a class="botton-x-right" @click="drawer = !drawer"><i class="fas fa-times"></i></a>
        <ul class="nav navbar-nav d-lg-flex w-full dis-flex-c">
          <div class="nav-header-mobile dis-flex-c-v w-full bggrey text-center">
            <li class="nav-item dropdown mt-40" v-if="loginSuccess">
            <span
              class="dis-flex-c-v"
              id="navbarDropdown"
              @click="handleSelect('5')"
            >
              <button class="circle">
                <span class="initials" v-if="initials">{{ initials }}</span>
                <span class="initials" v-else
                  ><i class="el-icon-loading"></i
                ></span>
              </button>
            </span>
            <div
              class="dropdown-menu custom-dropdown disable-select"
              aria-labelledby="navbarDropdown"
            >
              <a class="dropdown-item disable-select" @click="handleSelect('5')"
                ><i class="fas fa-user-edit mr-5"></i>Profile</a
              >
              <a
                class="dropdown-item disable-select"
                data-bs-toggle="modal"
                data-bs-target="#ModalCenterPassword"
                @click="resetChangePassword()"
                ><i class="fas fa-lock mr-5"></i>Change password</a
              >
              <div class="dropdown-divider disable-select"></div>
              <a class="dropdown-item" @click="logOut"
                ><i class="fas fa-sign-out-alt mr-5"></i>Log out</a
              >
            </div>
            <span class="mt-10 dis-block bold fs-16">{{ firstname }}</span>
            <span class="dis-block fs-14 mb-2 mt-1">{{ email }}</span>
          </li>
          </div>
          <div v-if="!loginSuccess">
            <img class="logo-header-mobile" src="../../assets/images/logo.png">
          </div>
          <li
            :class="{ activeTab: tab === '1', 'nav-item': tab !== '1', 'mt-30': !loginSuccess, 'mt-20': loginSuccess }"
            @click="handleSelect('1')"
          >
            <a class="nav-link custom-link disable-select"><i class="fas fa-house-user mr-5"></i>Home</a>
          </li>
          <div class="nav-link custom-link disable-select" v-if="loginSuccess"  data-bs-target="#ModalNotification"
              data-bs-toggle="modal"
          >
           <i class="fas fa-bell" v-if="!activeNotification"></i>
           <el-badge
              v-if="loginSuccess"
              :value="activeNotification"
              :max="9"
              class="item mt-7"
            >
            </el-badge> <span class="ml-3 fs-14">Notifications</span>
          </div>
            <div
            class="dropdown-menu custom-messages-dropdown"
            aria-labelledby="messagesDropdown"
          >
            <h6 class="dropdown-header text-center">Notification Center</h6>
            <div class="dropdown-divider"></div>
            <el-empty
              v-if="notifications.length === 0 && !loading"
              description="There are no notifications to display."
              :image-size="100"
            ></el-empty>
            <div class="main-loading" v-if="loading">
              <span class="a span-custom" style="--i: 27"></span>
              <span class="b span-custom" style="--i: 27"></span>
              <span class="c span-custom" style="--i: 27"></span>
              <span class="d span-custom" style="--i: 27"></span>
              <span class="e span-custom" style="--i: 27"></span>
            </div>
            <div class="dropdown-body stroke-hover" v-else>
              <div
                class="dropdown-item disable-select d-flex align-items-center"
                href="#"
                v-for="notification in notifications"
                v-bind:key="notification"
              >
                <div
                  class="custom-space-left"
                  v-if="notification.active === '1'"
                  :style="{ 'background-color': notification.color }"
                ></div>
                <div
                  :class="{ 'font-weight-bold': notification.active === '1' }"
                  data-bs-toggle="modal"
                  data-bs-target="#modalShowNotification"
                  @click="onClickNotification(notification)"
                >
                  <div class="text-truncate">
                    {{ replace(notification.message) }}
                    <div class="small text-right fs-10">
                      <i
                        class="el-icon-user"
                        v-if="
                          notification.clientName !== '' &&
                          notification.type !== 'shiftStart'
                        "
                      ></i>
                      {{
                        notification.clientName &&
                        notification.type !== 'shiftStart'
                          ? notification.clientName
                          : ''
                      }}
                      <i class="el-icon-time"></i>
                      {{
                        $moment(notification.startDateTimeNotif, [
                          'YYYY-MM-DD HH:mm:ss'
                        ]).fromNow()
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item disable-select text-center small danger text-up bold"
              href="#"
              v-if="
                !loading &&
                notifications.length !== 0 &&
                desactiveNotification !== null
              "
              @click="onClickDeleteAllSeenNotification()"
              >Delete all seen notifications</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item disable-select text-center small danger text-up bold"
              href="#"
              v-if="!loading && notifications.length !== 0"
              @click="onClickDeleteAllNotification()"
              >Delete all notifications</a
            >
          </div>
          <li
            :class="{ activeTab: tab === '7', 'nav-item': tab !== '7', 'm-t-10': activeNotification}"
            @click="handleSelect('7')" v-if="loginSuccess"
          >
          <a class="nav-link custom-link disable-select"><i class="fas fa-calendar-alt mr-5"></i>Shifts</a
              ></li>
          <li
            :class="{ activeTab: tab === '8', 'nav-item': tab !== '8'}"
            @click="handleSelect('8')" v-if="loginSuccess"
          >
              <a class="nav-link custom-link disable-select"
                ><i class="fas fa-calendar-check mr-5"></i>Activities</a
              ></li>
          <li
            :class="{ activeTab: tab === '2', 'nav-item': tab !== '2' }"
            @click="handleSelect('2')"
          >
            <a class="nav-link custom-link disable-select"><i class="fas fa-file-signature mr-5"></i>Contact</a>
          </li>
          <li
            :class="{ activeTab: tab === '5', 'nav-item': tab !== '5' }"
            @click="handleSelect('5')" v-if="loginSuccess"
          >
            <a class="nav-link custom-link  disable-select"><i class="fas fa-user-cog mr-5"></i>Settings</a>
          </li>
          <li
            @click="logOut" v-if="loginSuccess"
          >
            <a class="nav-link custom-link disable-select"><i class="fas fa-sign-out-alt mr-5"></i>Log out</a>
          </li>
          <li v-if="mode === 'admin'"
            :class="{ activeTab: tab === '6', 'nav-item': tab !== '6'}"
            @click="handleSelect('6')"
          >
              <a
                class="nav-link custom-link disable-select"
                ><i class="el-icon-s-operation mr-5"></i>Admin Dashboard</a
              >
            </li>
          <!--<li class="nav-item notification" v-if="loginSuccess"><i :class="{'el-icon-bell': !notificationFound, 'el-icon-message-solid': notificationFound }" data-toggle="dropdown"></i></li>-->

          <li :class="{ activeTab: tab === '3', 'nav-item': tab !== '3' }" @click="handleSelect('3')" v-if="!loginSuccess">
            <a class="nav-link custom-link disable-select"
              ><i class="fas fa-user"></i> Sign Up</a
            >
          </li>
          <li class="li-login-mobile" v-if="!loginSuccess">
            <button class="btn btn-aqua" @click="handleSelect('4')">
              <i class="fas fa-sign-in-alt"></i> Login
            </button>
          </li>
          <div class="footer-header-mobile">&copy; 2022 Euphoria Healthcare</div>
        </ul>
      </div>
      </el-drawer>
      <div
        class="navbar-collapse stroke-hover custom-navbar"
        id="navbarSupportedContent"
      >
        <ul class="nav navbar-nav d-lg-flex align-items-center">
          <li
            :class="{ activeTab: tab === '1', 'nav-item': tab !== '1' }"
            @click="handleSelect('1')"
          >
            <a class="nav-link custom-link disable-select">Home</a>
          </li>
          <li
            :class="{ activeTab: tab === '2', 'nav-item': tab !== '2' }"
            @click="handleSelect('2')"
          >
            <a class="nav-link custom-link m-r-8 disable-select">Contact</a>
          </li>

          <!--<li class="nav-item notification" v-if="loginSuccess"><i :class="{'el-icon-bell': !notificationFound, 'el-icon-message-solid': notificationFound }" data-toggle="dropdown"></i></li>-->
          <div
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="ml-5"
          >
            <el-badge
              v-if="loginSuccess"
              :value="activeNotification"
              :max="9"
              class="item"
            >
              <el-button
                class="notf-btn nav-link custom-link disable-select"
                :icon="
                  activeNotification !== null
                    ? 'el-icon-message-solid'
                    : 'el-icon-bell'
                "
              ></el-button>
            </el-badge>
          </div>
          <div
            class="dropdown-menu custom-messages-dropdown"
            aria-labelledby="messagesDropdown"
          >
            <h6 class="dropdown-header text-center">Notification Center</h6>
            <div class="dropdown-divider"></div>
            <el-empty
              v-if="notifications.length === 0 && !loading"
              description="There are no notifications to display."
              :image-size="100"
            ></el-empty>
            <div class="main-loading" v-if="loading">
              <span class="a span-custom" style="--i: 27"></span>
              <span class="b span-custom" style="--i: 27"></span>
              <span class="c span-custom" style="--i: 27"></span>
              <span class="d span-custom" style="--i: 27"></span>
              <span class="e span-custom" style="--i: 27"></span>
            </div>
            <div class="dropdown-body stroke-hover" v-else>
              <div
                class="dropdown-item d-flex align-items-center disable-select"
                href="#"
                v-for="notification in notifications"
                v-bind:key="notification"
              >
                <div
                  class="custom-space-left"
                  v-if="notification.active === '1'"
                  :style="{ 'background-color': notification.color }"
                ></div>
                <div
                  :class="{ 'font-weight-bold': notification.active === '1' }"
                  data-bs-toggle="modal"
                  data-bs-target="#modalShowNotification"
                  @click="onClickNotification(notification)"
                >
                  <div class="text-truncate">
                    {{ replace(notification.message) }}
                    <div class="small text-right fs-10">
                      <i
                        class="el-icon-user"
                        v-if="
                          notification.clientName !== '' &&
                          notification.type !== 'shiftStart'
                        "
                      ></i>
                      {{
                        notification.clientName &&
                        notification.type !== 'shiftStart'
                          ? notification.clientName
                          : ''
                      }}
                      <i class="el-icon-time"></i>
                      {{
                        $moment(notification.startDateTimeNotif, [
                          'YYYY-MM-DD HH:mm:ss'
                        ]).fromNow()
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item text-center small danger text-up bold disable-select"
              href="#"
              v-if="
                !loading &&
                notifications.length !== 0 &&
                desactiveNotification !== null
              "
              @click="onClickDeleteAllSeenNotification()"
              >Delete all seen notifications</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item text-center small danger text-up bold disable-select"
              href="#"
              v-if="!loading && notifications.length !== 0"
              @click="onClickDeleteAllNotification()"
              >Delete all notifications</a
            >
          </div>
          <li class="nav-item dropdown">
            <span
              class="dropdown-toggle"
              v-if="loginSuccess"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <button class="circle">
                <span class="initials" v-if="initials">{{ initials }}</span>
                <span class="initials" v-else
                  ><i class="el-icon-loading"></i
                ></span>
              </button>
            </span>
            <div
              class="dropdown-menu custom-dropdown"
              aria-labelledby="navbarDropdown"
            >
              <a class="dropdown-item disable-select" @click="handleSelect('5')"
                ><i class="fas fa-user-edit mr-5"></i>Profile</a
              >
              <a class="dropdown-item disable-select" @click="handleSelect('7')"
                ><i class="el-icon-date mr-5"></i>Shifts</a
              >
              <a class="dropdown-item disable-select" @click="handleSelect('8')"
                ><i class="el-icon-tickets mr-5"></i>Activities</a
              >
              <a
                class="dropdown-item disable-select"
                @click="handleSelect('6')"
                v-if="mode === 'admin'"
                ><i class="el-icon-s-operation mr-5"></i>Admin Dashboard</a
              >
              <a
                class="dropdown-item disable-select"
                data-bs-toggle="modal"
                data-bs-target="#ModalCenterPassword"
                @click="resetChangePassword()"
                ><i class="fas fa-lock mr-5"></i>Change password</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item disable-select" @click="logOut"
                ><i class="fas fa-sign-out-alt mr-5"></i>Log out</a
              >
            </div>
          </li>
          <li v-if="!loginSuccess" :class="{
                'nav-link custom-link activeTab disable-select': tab === '3',
                'nav-link custom-link disable-select': tab !== '3'
              }">
            <a
              :class="{
                'nav-link custom-link activeTab disable-select': tab === '3',
                'nav-link custom-link disable-select': tab !== '3'
              }"
              @click="handleSelect('3')"
              ><i class="fas fa-user"></i> Sign Up</a
            >
          </li>
          <li v-if="!loginSuccess">
            <button class="btn btn-aqua" @click="handleSelect('4')">
              <i class="fas fa-sign-in-alt"></i> Login
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div
    class="modal fade"
    id="modalShowNotification"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="titleCenter">Notification details</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="wrapper bg-white">
            <el-divider content-position="left" class="mt-20"
              >MESSAGE</el-divider
            >
            <div class="row">
              <p class="wb-all">{{ replace(notif.message) }}</p>
            </div>
            <el-divider content-position="right" class="mt-20"
              ><i class="el-icon-time"></i>
              {{
                $moment(
                  notif.startDateTimeNotif,
                  ['YYYY-MM-DD HH:mm:ss'],
                  ['YYYY-MM-DD HH:mm:ss']
                ).fromNow()
              }}</el-divider
            >
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
    <div
    class="modal fade"
    id="ModalNotification"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="titleCenter">Notification Center</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body w-full">
            <div class="dropdown-divider"></div>
            <el-empty
              v-if="notifications.length === 0 && !loading"
              description="There are no notifications to display."
              :image-size="100"
            ></el-empty>
            <div class="main-loading" v-if="loading">
              <span class="a span-custom" style="--i: 27"></span>
              <span class="b span-custom" style="--i: 27"></span>
              <span class="c span-custom" style="--i: 27"></span>
              <span class="d span-custom" style="--i: 27"></span>
              <span class="e span-custom" style="--i: 27"></span>
            </div>
            <div class="dropdown-body stroke-hover disable-select" v-else>
              <div
                class="dropdown-item d-flex align-items-center disable-select"
                v-for="notification in notifications"
                v-bind:key="notification"
              >
                <div
                  class="custom-space-left"
                  v-if="notification.active === '1'"
                  :style="{ 'background-color': notification.color }"
                ></div>
                <div
                  class="w-full"
                  :class="{ 'font-weight-bold': notification.active === '1' }"
                  data-bs-toggle="modal"
                  data-bs-target="#modalShowNotification"
                  @click="onClickNotification(notification)"
                >
                  <div class="w-full">
                    {{ replace(notification.message) }}
                    <div class="small text-right fs-10">
                      <i
                        class="el-icon-user"
                        v-if="
                          notification.clientName !== '' &&
                          notification.type !== 'shiftStart'
                        "
                      ></i>
                      {{
                        notification.clientName &&
                        notification.type !== 'shiftStart'
                          ? notification.clientName
                          : ''
                      }}
                      <i class="el-icon-time"></i>
                      {{
                        $moment(notification.startDateTimeNotif, [
                          'YYYY-MM-DD HH:mm:ss'
                        ]).fromNow()
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown-divider"></div>

        </div>
        <div class="modal-footer">
            <a
              class="dropdown-item text-center small danger text-up bold disable-select"
              href="#"
              v-if="
                !loading &&
                notifications.length !== 0 &&
                desactiveNotification !== null
              "
              @click="onClickDeleteAllSeenNotification()"
              >Delete all seen notifications</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item text-center small danger text-up bold disable-select"
              href="#"
              v-if="!loading && notifications.length !== 0"
              @click="onClickDeleteAllNotification()"
              >Delete all notifications</a
            >
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="ModalCenterPassword"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="titleCenter">Change password</h5>
          <button
            type="button"
            class="close"
            @click="resetChangePassword()"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body pb-2">
            <div class="form-group mb-2">
              <label class="form-label">New password</label>
                  <el-input :type="showPassword ? 'text' : 'password'" onkeypress="return event.charCode != 32" placeholder="Write your new password" v-model="password" clearable></el-input>
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Repeat new password</label>
              <el-input :type="showPassword ? 'text' : 'password'" onkeypress="return event.charCode != 32" placeholder="Confirm your new password" v-model="confirmPassword" clearable></el-input>
            </div>
            <div class="mb-2">
              <el-checkbox v-model="showPassword">Show password</el-checkbox>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-cancel"
            @click="resetChangePassword()"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-aqua"
            :disabled="checkFields()"
            @click="changePassword()"
          >
            <div v-if="!isSaving">Save changes</div>
            <div v-else><i class="el-icon-loading"></i></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getProfile,
  updateUser,
  getUser,
  getNotificationByUser,
  updateNotificationActive,
  deleteAllNotifications,
  deleteAllSeenNotification
} from '../../utils/axios'
import {
  getInitials,
  checkPassword,
  verifyToken,
  removeToken,
  notification,
  sortByDateTime,
  replace_
} from '../../components/common/common'

export default {
  name: 'HeaderPage',
  props: {
    msg: String
  },
  data() {
    return {
      loginSuccess: false,
      showPassword: false,
      tab: '1',
      notif: '',
      password: '',
      drawer: false,
      isSaving: false,
      confirmPassword: '',
      email: '',
      firstname: '',
      mode: '',
      shiftDetail: '',
      activeNotification: null,
      desactiveNotification: null,
      notifications: [],
      initials: '',
      loading: true
    }
  },
  mounted() {
    if (sessionStorage.getItem('tab')) this.tab = sessionStorage.getItem('tab')
    if (verifyToken()) {
      this.loginSuccess = true
      this.getData()
    } else this.loginSuccess = false
  },
  async created() {
    if (verifyToken()) await this.promiseNotification()
  },
  methods: {
    replace(text) {
      if (text !== undefined) {
        return replace_(text)
      }
    },
    getData() {
      getProfile().then(
        (resp) => {
          const name = resp.data[0].firstname + ' ' + resp.data[0].lastname
          this.firstname = resp.data[0].firstname
          sessionStorage.setItem('IDUSER', resp.data[0].idUser)
          getUser().then(
            (resp) => {
              this.mode = resp.data[0].mode
              this.email = resp.data[0].email
            },
            (err) => {
              console.log('errUser ' + err)
            }
          )
          this.initials = getInitials(name)
        },
        (err) => {
          this.initials = ''
          console.log(err.message)
          if (err.message.includes('401')) {
            removeToken()
            this.$router.go()
          }
        }
      )
    },
    onClickDeleteAllNotification() {
      deleteAllNotifications().then(
        () => {
          this.promiseNotification()
          notification('Notifications deleted successfully!', 'success')
        },
        (err) => {
          notification(err.message, '', '')
        }
      )
    },
    onClickDeleteAllSeenNotification() {
      deleteAllSeenNotification({ noActive: '' }).then(
        () => {
          this.promiseNotification()
          notification('Seen notifications deleted successfully!', 'success')
        },
        (err) => {
          notification(err.message, '', '')
        }
      )
    },
    onClickNotification(e) {
      this.notif = e
      this.shiftDetail = this.notif.details
        ? JSON.parse(this.notif.details)
        : ''
      this.notif.active = '0'
      updateNotificationActive(this.notif).then(
        () => {
          this.promiseNotification()
        },
        (err) => notification(err.message, '', '')
      )
    },
    promiseNotification() {
      return new Promise((resolve, reject) => {
        this.notifications = []
        this.loading = true
        this.activeNotification = null
        this.desactiveNotification = null
        getNotificationByUser()
          .then(
            (response) => {
              if (response.status === 200) {
                if (response.data) {
                  this.notifications = response.data.sort(sortByDateTime)
                  this.notifications.forEach((notification) => {
                    if (notification.active === '1') this.activeNotification += 1
                    else this.desactiveNotification += 1
                  })
                  this.loading = false
                }
              } else this.loading = false
              resolve(response.data)
            },
            (error) => {
              this.loading = false
              reject(error)
            }
          )
          .catch((err) => {
            console.log(err)
          })
      })
    },
    getInitials(name) {
      // eslint-disable-next-line prefer-regex-literals
      const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
      let initials = [...name.matchAll(rgx)] || []
      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase()
      return initials
    },
    handleSelect(key) {
      this.drawer = false
      switch (key) {
        case '1':
          this.$router.push('home')
          break
        case '2':
          this.$router.push('contact')
          break
        case '3':
          this.$router.push('register')
          break
        case '4':
          this.$router.push('login')
          break
        case '5':
          this.$router.push('profile')
          break
        case '6':
          this.$router.push('dashboard')
          break
        case '7':
          this.$router.push('shifts')
          break
        case '8':
          this.$router.push('myshifts')
          break
        default:
          break
      }
      sessionStorage.setItem('tab', key)
      this.tab = key
    },
    checkFields() {
      if (this.password.length > 0 && this.confirmPassword.length > 0) {
        if (
          this.isSaving ||
          this.password !== this.confirmPassword ||
          !checkPassword(this.password, this.confirmPassword)
        ) {
          return true
        }
        return false
      } else {
        if (this.password && this.confirmPassword) return false
        return true
      }
    },
    resetChangePassword() {
      this.password = ''
      this.isSaving = false
      this.confirmPassword = ''
    },
    handleClose() {
      this.drawer = false
    },
    changePassword() {
      const formUser = {}
      formUser.password = this.password
      this.isSaving = true
      updateUser(formUser).then(
        (resp) => {
          notification(
            'The password has been updated successfully !',
            'success'
          )
          removeToken()
          this.$router.push('home')
          this.$router.go()
        },
        (err) => {
          this.isSaving = false
          this.updateEmailPass = false
          this.resetChangePassword()
          if (err.response === undefined) this.notification(err.message)
          else this.notification(err.response.data.result.error_msg)
          this.$router.go()
        }
      )
    },
    logOut() {
      removeToken()
      sessionStorage.removeItem('IDUSER')
      this.$router.go()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
//TEST-NAVBAR-HIDE
.navbar {
  /*   z-index should be above .close-navbar-toggler */
  z-index: 2;
}
.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
.close-navbar-toggler.collapsed {
  z-index: -1;
}
//FIN TEST-NAVBAR-HIDE
.text-truncate {
  max-width: 14rem;
  min-width: 14rem;
}

.danger:hover {
  background-color: #e20404 !important;
}
.dropdown-body {
  max-height: 220px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}
.notf-btn {
  border: none !important;
  background-color: transparent;
  color: black !important;
  padding-right: 0 !important;
  margin-right: 9px !important;
  font-size: 18px !important;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: none !important;
    border: none !important;
    background-color: transparent !important;
  }
}
</style>

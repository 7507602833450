<template>
  <div class="custom-container-footer mt-60 mb-30">
    <CookieConsent v-if="$route.name !== 'cookies'"
    message="We use cookies for user analysis and on-page improvements!"
    link-label="Learn about cookies"
    href="cookies"
  />
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="footer-logo-part">
          <img class="logo-footer" src="../../assets/images/logo-footer.png" />
          <p class="mt-10">
            <a class="a-custom no-space mr-8" href="/privacy" target="_blank">Privacy Policy</a>
            <a class="a-custom no-space d-block" href="/terms-conditions" target="_blank">Terms and Conditions</a>
            <a class="a-custom no-space d-block " href="/cookies" target="_blank">Cookies Policy</a>
            <a class="a-custom no-space d-block" href="/about-us" target="_blank">About us</a>

          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="footer-location mt-40">
          <h5 class="bold-500">Our Location</h5>
          <p class="mt-30 text-footer-page">
            Flat 5, 41 Stone St, Llandovery SA20 0BZ, UK
          </p>
          <a class="btn btn-aqua" style="border-radius: 5px" href="https://maps.google.com/?q=Flat 5, 41 Stone St, Llandovery SA20 0BZ, UK" target="_blank">
            SHOW ON MAP
          </a>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="footer-schedule mt-40">
          <h5 class="bold-500">Working time</h5>
          <p class="no-space mt-30 text-footer-page">Mon - Fri - <b>09:00 - 18:00</b></p>
          <p class="no-space text-footer-page">Sat - Sun - <b>Closed</b></p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="footer-contact mt-40">
          <h5 class="bold-500">Find us on social media</h5>
          <!-- <a class="a-custom no-space" :href="`tel:${numbercall}`"
            ><p class="no-space mt-30">Telephone number: {{ number }}</p></a
          >
          <a class="a-custom no-space" :href="`mailto:${email}`"
            ><p class="no-space">E-mail: {{ email }}</p></a
          > -->
          <div class="footer-socials mt-30">
            <ul class="socials text-center clearfix">
              <li>
                <a :href="instalink" class="icon-instagram">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a :href="fblink" class="icon-facebook">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#updateSoon" class="icon-twitter">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="h-line mt-60"></div>
    <div class="copyright text-center">
      <p class="copyright-p no-space mt-30">
        &copy; 2022 <span class="bold">Euphoria Healthcare</span>. All Rights
        Reserved
      </p>
    </div>
  </div>
</template>
<script>
import CookieConsent from 'vue-cookieconsent-component/src/components/CookieConsent.vue'
export default {
  components: { CookieConsent },
  name: 'FooterPage',
  data() {
    return {
      email: 'contact@euphoriahealthcare.co.uk',
      number: '(+44) 07721153652',
      numbercall: '+4407721153652',
      fblink: 'https://www.facebook.com/profile.php?id=100085312668771',
      instalink: 'https://www.instagram.com/euphoria_healthcare_limited/?igshid=YmMyMTA2M2Y%3D'
    }
  }
}
</script>
<style lang="scss" scoped>

ul {
  list-style-type: none;
}
p {
  font-size: 0.9rem;
}

</style>

<template>
  <div class="contact-header-web blue-bg dis-flex-c-v">
    <div class="left-part-header">
      <div class="address">
        <a target="_blank" href="https://maps.google.com/?q=Flat 5, 41 Stone St, Llandovery SA20 0BZ, UK"><i class="fas fa-location-arrow fs-18"></i><span class="ml-5">{{address}}</span></a>
      </div>
      <div class="number">
        <a :href="`tel:${numbercall}`"><i class="fas fa-mobile-alt fs-18"></i><span class="ml-7">{{number}}</span></a>
      </div>
    </div>
    <div class="right-part-header">
      <a :href="twitter" class="twitter-icon"><i class="fab fa-twitter-square fs-20"></i></a>
      <a :href="facebook" target="_blank" class="facebook-icon"><i class="fab fa-facebook-square fs-20"></i></a>
      <a :href="instagram" target="_blank" class="google-icon"><i class="fab fa-instagram-square fs-20"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
  props: {
    address: {
      type: String,
      default: 'Flat 5, 41 Stone St, Llandovery SA20 0BZ, UK'
    },
    number: {
      type: String,
      default: '(+44) 07721153652'
    },
    numbercall: {
      type: String,
      default: '+4407721153652'
    },
    facebook: {
      type: String,
      default: 'https://www.facebook.com/profile.php?id=100085312668771'
    },
    twitter: {
      type: String,
      default: '#updateSoon'
    },
    instagram: {
      type: String,
      default: 'https://www.instagram.com/euphoria_healthcare_limited/?igshid=YmMyMTA2M2Y%3D'
    }
  }
}
</script>

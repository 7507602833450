import { createRouter, createWebHistory } from 'vue-router'
import cookies from 'vue-cookies'
const routes = [
  {
    path: '/',
    name: '*',
    component: () => import('../components/home/home.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/home/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/login-register/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/login-register/register.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true
    },
    component: () => import('../components/profile/profile.vue')
  },
  {
    path: '/shifts',
    name: 'shifts',
    meta: {
      requiresAuth: true
    },
    component: () => import('../components/shifts/shiftsCalendar.vue')
  },
  {
    path: '/myshifts',
    name: 'my-shifts',
    meta: {
      requiresAuth: true
    },
    component: () => import('../components/shifts/shiftsMyCalendar.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import('../components/admin-dashboard/main.vue')
  },
  {
    path: '/activation',
    name: 'activation-account',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/activation-account/activationAccount.vue')
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/legal/termsAndConditions.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/legal/privacyNotice.vue')
  },
  {
    path: '/cookies',
    name: 'cookies',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/legal/cookies.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/form-contact/formContact.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/about/aboutUs.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      requiresAuth: false
    },
    component: () => import('../components/forgot-password/resetPassword.vue')
  }]
const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0, behavior: 'smooth' }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!cookies.get('_q3@fd2')) next({ name: 'login' })
    else next()
  } else {
    if (to.name !== undefined) {
      if ((to.name.includes('login') || to.name.includes('register')) && cookies.get('_q3@fd2')) next({ name: 'home' })
      else next()
    } else {
      next({ name: 'home' })
    }
  }
})
export default router

/* eslint-disable vue/no-multiple-template-root */
<template>
  <Contact v-if="$route.name !== 'dashboard' && $route.name !== 'home-dashboard'" />
  <Header v-if="$route.name !== 'dashboard' && $route.name !== 'home-dashboard'"/>
  <router-view/>
  <Scroll v-if="$route.name !== 'profile'"/>
  <Footer v-if="$route.name !== 'dashboard' && $route.name !== 'home-dashboard'"/>
</template>

<script>
import Header from './components/header/header.vue'
import Contact from './components/contact/contact.vue'
import { provide } from 'vue'
import store from './store/store'
import Footer from './components/footer/footer.vue'
import Scroll from './components/common/goTop/goTop.vue'

export default {
  name: 'App',
  setup () {
    provide('store', store)
  },
  components: {
    Header,
    Contact,
    Footer,
    Scroll
  }
}
</script>
